import moment from "moment";
import phoneFilter from "@/helpers/filters/phoneFilter";

const tableColsRewiev = [
  {
    text: "Текст сообщения",

    renderFunction: value => {
      if (value?.text.length > 40) {
        return value.text.substr(0, 40) + "...";
      }
      return value?.text;
    },
    value: "text"
  },
  {
    text: "Телефон",
    value: "phone_number.",
    width: 20,
    renderFunction: value => {
      return value?.created_by?.phone_number != null
        ? phoneFilter.execute(value?.created_by?.phone_number)
        : "-";
    }
  },
  {
    text: "Дата отправки сообщения",
    value: "created_at",
    sort: true,
    renderFunction: value =>
      `${moment(value?.created_at * 1000).format("DD.MM.YYYY HH:mm")}`
  }
];

export default tableColsRewiev;
