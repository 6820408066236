<template>
  <div>
    <MainLayout>
      <template v-slot:default>
        <TableFilters :queries="queries">
          <template v-slot:filter-title>
            <span v-if="sourceObject.count" class="title-filter">
              Всего: {{ sourceObject.count }}
            </span>
          </template>
        </TableFilters>
        <TableLayout
          :loading="isDateLoaded"
          to="reviewInfos"
          :table-data="sourceObject.results"
          :header="header"
          :source-object="sourceObject"
        />
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsRewiev from "@/helpers/tableCols/review";

// import {
//   actionMessage,
//   successMessageDictionary
// } from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "CardsPage",
  data() {
    return {
      dialog: false, // диалоговое окно popup удаления
      key: null,
      loadingBtn: false,
      queries: query.review
    };
  },
  components: { TableFilters, TableLayout, MainLayout },
  computed: {
    header() {
      return tableColsRewiev;
    },
    sourceObject() {
      return this.$store.getters.getPageReviewList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    }
  },
  methods: {},
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageReviewList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
          // type: this.type
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageReviewList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store
      .dispatch("clearCardsPage")
      .then(() => {
        this.$store.dispatch("clearSortQuery");
      })
      .then(() => next());
  }
};
</script>
